<template>
  <b-row>
    <b-col md="4">
      <b-card-normal title="İptal Politika Tanımlama" :showLoading="show">
        <template v-slot:body>
          <tanimlama :politikalar="politikalar" @showLoading="show = $event" @refresh="handlerGetData" :updateData="updateData" />
        </template>
      </b-card-normal>
    </b-col>
    <b-col md="8" order-sm="12">
      <b-card-normal title="İptal Politikaları" :showLoading="show">
        <template v-slot:body>
          <list :data="politikalar" @update="handlerUpdate($event)" @refresh="handlerGetData" />
        </template>
      </b-card-normal>
    </b-col>
  </b-row>
</template>

<script>
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import Tanimlama from './component/tanimlama.vue';
import List from './component/list.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    BCardNormal,
    Tanimlama,
    List,
  },
  data: () => ({
    show: false,
    politikalar: [],
    rows: [],
    updateData: {},
  }),
  computed: {
    ...mapGetters(['getIptalPolitikalari']),
  },
  async created() {
    await this.$store.dispatch('iptalPolitikaListele');
    this.handlerGetData();
  },
  methods: {
    async handlerGetData() {
      this.show = true;
      this.rows = [];
      this.politikalar = [];

      const politikalar = await this.getIptalPolitikalari;

      politikalar.forEach((el) => {
        this.politikalar.push({ ...el, k_no: el.k_no, baslik: el.icerik[0].baslik });
      });
      // politikalar.forEach((el, i) => {
      //   if (el.kategori_k_no == null) this.rows.push({ ...el, children: [] });
      //   if (el.kategori_k_no != null) {
      //     console.log(el);
      //     let index = this.rows.findIndex((x) => x.k_no == el.kategori_k_no);
      //     this.rows[index].children.push(el);
      //   }
      // });

      console.log(this.politikalar);

      this.show = false;
    },
    handlerUpdate(event) {
      this.updateData = event;
    },
  },
};
</script>

<style lang="scss" scoped></style>
